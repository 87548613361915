<template>
  <ValidationProvider :name="$t('role')" :rules="sRules" vid="role" slim>
    <v-select
      v-model="arRoles"
      slot-scope="{ errors, valid }"
      :error-messages="errors"
      :success="valid"
      :items="roles"
      :label="$t('role')"
      :return-object="false"
      :menu-props="{ offsetY: true }"
      item-value="code"
      item-text="name"
      hide-details="auto"
      small-chips
      multiple
      outlined
      clearable
      dense
    />
  </ValidationProvider>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import type { Group } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class RolesSelect extends Vue {
  @VModel({ type: Array }) arRoles!: string[];
  @Prop(Boolean) readonly required!: boolean;

  get roles() {
    return AppModule.groups
      .filter((obGroup: Group) => {
        return !["guest", "registered"].includes(obGroup.code);
      })
      .getModels();
  }

  get sRules() {
    return this.required ? "required" : "";
  }
}
</script>
