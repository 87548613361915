<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="bActive"></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obUser.name" required />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obUser.last_name" label="last.name" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-email v-model="obUser.email" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <roles-select required v-model="obUser.groups" />
    </v-col>

    <v-col cols="12">
      <v-textarea
        :label="$t('description')"
        outlined
        v-model="obUser.preview_text"
      />
    </v-col>

    <v-col cols="12">
      <form-field-passwords v-model="obUser" :fixed="!obUser.id" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { User } from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";
import RolesSelect from "@/modules/users/components/RolesSelect.vue";

@Component({
  components: { RolesSelect },
})
export default class UsersSettings extends Vue {
  @VModel({ type: Object, default: () => new User() }) obUser!: User;

  get roles() {
    return AppModule.groups.getModelList();
  }

  get bActive() {
    return this.obUser ? this.obUser.get("is_activated", true) : true;
  }

  set bActive(bValue: boolean) {
    this.obUser.set("is_activated", bValue);
  }
}
</script>
